import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import MyTextField from "../../../components/text/MyTextField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoadButton from "../../../components/LoadButton";

const SignUp = () => {
  const [state, setState] = React.useState(true);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (event) => {
    console.log(event);
    navigate("/contas/completar-cadastro");
  };

  const inputs = [
    {
      name: "email",
      label: "E-mail",
      grid: 12,
      helperText: "O endereço de email para cadastro é obrigatório.",
      required: true,
    },
    {
      name: "cpf",
      label: "Digite o seu CPF",
      grid: 12,
      helperText: "O cpf é obrigatório para o cadastro.",
      required: true,
    },
  ];
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          my: 2,
          display: "flex",
          flexDirection: "column",
          p: 4,
        }}
        component={Paper}
      >
        {state ? (
          <>
            <Typography component="h1" variant="h4">
              Cadastro
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              Para continuar o seu cadastro, precisamos que você preencha os
              seguintes dados:
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 3 }}
            >
              <MyTextField
                inputs={inputs}
                register={register}
                errors={errors}
              />
              <LoadButton
              props={{
                type:"submit",
                variant:"contained",
                size:'large',
                sx:{ mt: 3, mb: 2 },
                fullWidth: true,
                loading: false
              }}
                
              >
                continuar
              </LoadButton>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    onClick={() => navigate("/contas/login")}
                    variant="subtitle1"
                    sx={{cursor:'pointer'}}
                  >
                    Já tem conta? Entrar
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h3" mt={2}>
              Pronto!
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              Enviamos as instruções de confirmação do cadastro para o e-mail
              informado.{" "}
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              Não se esqueça de conferir a sua caixa de spam.{" "}
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default SignUp;
