import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const HowToBuy = () => {
  const _string = `

    Para participar de um leilão é muito simples, você só precisa ter um cadastro válido e ativo.
    
    Caso ainda não tenha um cadastro, clique no link Cadastre-se (canto superior direito) e preencha os dados solicitados.
    
    Após o preenchimento, você receberá um E-MAIL com LINK para Ativação de seu cadastro.
    
    Clique no link recebido para ativar seu cadastro. Pronto, você já está apto a efetuar lances.
    
    Para efetuar um lance, faça o login e clique no lote de seu interesse.
    
    Você será direcionado para a página da peça - clique no botão "Fazer Lance"
    
    Você poderá acompanhar sua participação no leilão clicando no link Minha conta.  
    `;

  const _obs = `
    <strong>Lance Automático</strong>
    
    Com o Lance Automático, o sistema fará lances mínimos, sempre que necessário, para que você continue como vencedor até que o valor máximo seja alcançado.
    
    Para fazer um lance automático, preencha o valor máximo que deseja pagar pelo lote e MARQUE o checkbox "Lance Automático".
    
    <strong>Observações:</strong>
    
    1 - Leilão não é comércio eletrônico. Todo leilão é certificado por um Leiloeiro(a) público oficial;
    
    2 - Antes de registrar seu lance, leia os "Termos e condições do leilão" para verificar os procedimentos para retirada / envio e as condições de pagamento; Lembrando que cada leilão possui regras específicas.
    
    3 - Você pode efetuar lances antes da data do pregão, contudo, o lance vencedor será validado somente na data e horário informados no catálogo;
    
    4 - Lances efetuados após o encerramento do leilão serão aceitos ou não, a critério exclusivo do leiloeiro responsável;
    
    5 - E-mails de avisos para “Lotes vigiados” e “Lances automáticos” não são executados durante o pregão;
    
    6 - O comprador pagará ao leiloeiro uma comissão legal de 5% sobre o valor final da compra.
    
    <strong>Leilão</strong> - Forma de alienação de bens.
    
    <strong>Pregão</strong> - Forma de licitação pública, em data e horário definidos, onde é validado a escolha do melhor candidato pelo respectivo leiloeiro(a) responsável.`;

  return (
    <Container maxWidth="xl" pb={2}>
      <Typography variant="h3" my={3}>
        COMO COMPRAR
      </Typography>
      <Divider></Divider>
      <Grid container my={1}>
        <Grid item xs={12} md={6} sx={{ alignItems: "center" }}>
          {_string
            .split("\n")
            .filter((item) => item != "")
            .map((item) => {
              const theObj = { __html: item };
              return (
                <Typography mt={2}>
                  <div dangerouslySetInnerHTML={theObj} />
                </Typography>
              );
            })}
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
          <Box
            sx={{
              width: "auto !important",
              maxWidth: 555,
              height: 260,
              justifyContent: "flex-end",
              alignSelf:'flex-end',
              mt:1
            }}
          >
            <iframe
              width="100%"
              height="260"
              src="https://www.youtube.com/embed/vgcUw8VRsoM"
              title="Sistema de leilão eletrônico"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
        </Grid>

        <Grid item xs={12} mb={4}>
          {_obs
            .split("\n")
            .filter((item) => item != "")
            .map((item) => {
              const theObj = { __html: item };
              return (
                <Typography mt={2}>
                  <div dangerouslySetInnerHTML={theObj} />
                </Typography>
              );
            })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default HowToBuy;
