import { Box, Grid, Typography } from "@mui/material";

const EmBreve = () => {
  return (
    <Box sx={{ minHeight: "50vh",  display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={12} sx={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems: 'center'}}>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Página em construção
            </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmBreve;
