import { Call, Mail, Menu, Person, Phone } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../assets/configs/firebase.config";
import { signOut } from "firebase/auth";
import { FlexBox } from "./FlexBox";

let logo = require("../assets/logo.jpg");

const Navbar = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const handleLogout = () => {
    if (window.confirm("Tem certeza de que deseja sair do sistema?")) {
      signOut(auth);
      alert("Ate breve!");
      navigate("/");
    }
  };
  return (
    <AppBar position="sticky">
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "#f1f1f1",
          color: "#000",
          p: 1,
          px: 4,
        }}
      >
        <Container maxWidth="xl">
          <FlexBox >
            <Box sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                Esmeralda Leilões
              </Typography>
              <Typography sx={{ fontSize: 11 }}>
                CNPJ: 50.645.363/0001-52
              </Typography>
            </Box>

            <Box sx={{flexGrow:1}}/>

            <Box sx={{ textAlign: "right", justifyContent: "flex-end" }}>
              <FlexBox sx={{ justifyContent: "flex-end" }}>
                <Person />
                <Typography sx={{ fontSize: 11 }}>
                  Atendimento de 2ª a 6ª-feira das 10h30 às 17h.
                </Typography>
              </FlexBox>
              <FlexBox>
                <FlexBox>
                  <Phone />
                  <Typography sx={{ fontSize: 11 }}>
                    (21) 97252-5983 /{" "}
                  </Typography>
                </FlexBox>
                <FlexBox>
                  <Mail />
                  <Typography sx={{ fontSize: 11 }}>
                    esmeralda.leiloes@gmail.com
                  </Typography>
                </FlexBox>
              </FlexBox>
            </Box>
          </FlexBox>
        </Container>
      </Box>

      <Toolbar
        sx={{
          backgroundColor: "primary.main",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            my: 1,
            width: "100%",
            pt: { xs: 1, md: 0 },
            color: "#f1f1f1",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              px: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <img src={logo} height="70" />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {user ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontSize: 10,
                  textAlign: "right",
                }}
              >
                <Box sx={{ display: { xs: "block", md: "block" } }}>
                  <Typography variant="subtitle1" mb={1}>
                    Olá,{" "}
                    {user.displayName?.split(" ")[0] +
                      " " +
                      user.displayName?.split(" ").slice(-1)}
                  </Typography>
                  <FlexBox>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="small"
                      onClick={() => navigate("/minha-conta")}
                    >
                      Minha Conta
                    </Button>
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={handleLogout}
                    >
                      Sair
                    </Button>
                  </FlexBox>
                </Box>
              </Box>
            ) : (
              <>
                <FlexBox>
                  <Button
                    variant="text"
                    // size="small"
                    color="inherit"
                    onClick={() => navigate("/contas/login")}
                    // sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    entrar
                  </Button>
                  <Button
                    variant="outlined"
                    // size="small"
                    color="inherit"
                    onClick={() => navigate("/contas/cadastro")}
                    // sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    cadastrar
                  </Button>
                </FlexBox>
              </>
            )}
          </Container>
        </Box>
      </Toolbar>

      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Container maxWidth="xl">
          <Tabs
            variant={window.innerWidth <= 600 ? "scrollable" : "fullWidth"}
            centered={window.innerWidth <= 600 ? false : true}
            allowScrollButtonsMobile
            scrollButtons={window.innerWidth <= 600 ? false : true}
            aria-label="scrollable auto tabs example"
            textColor="secondary"
            indicatorColor="secondary"
            sx={{ p: 0, m: 0 }}
          >
            <Tab label="Home" onClick={() => navigate("/home")} />
            <Tab label="Catálogos" onClick={() => navigate("/catalogos")} />
            <Tab
              label="Como Comprar"
              onClick={() => navigate("/comprar")}
            />
            <Tab label="Como Vender" onClick={() => navigate("/vender")} />
            <Tab
              label="Fale Conosco"
              onClick={() => navigate("/contato")}
            />
          </Tabs>
        </Container>
      </Box>
    </AppBar>
  );
};

export default Navbar;
