import { Box, Divider, Grid, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import { FlexBox } from "../../../components/FlexBox";
import {
  ArrowUpward,
  Dashboard,
  Grading,
  Mail,
  ShoppingCart,
} from "@mui/icons-material";

const Resume = ({setValue}) => {
  return (
    <>
      <FlexBox sx={{ mb: 2 }}>
        <Dashboard sx={{ fontSize: 32 }} />
        <Typography variant="h4" fontWeight="bolder">
          Resumo
        </Typography>
      </FlexBox>
      <Divider />
      <Box my={1} p={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FlexBox sx={{ mb: 2 }}>
              <Mail sx={{ fontSize: 24 }} />
              <Typography variant="h5" fontWeight="bolder">
                Últimas Mensagens
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Link variant="subtitle1" sx={{cursor:'pointer'}} onClick={() => setValue(9)}>Ver todas as mensagens</Link>
              <Divider />
              <IconButton>
                <ArrowUpward />
              </IconButton>
            </FlexBox>
            <Divider />
            <Box my={1} p={1}>
              <Typography>Não existem mensagens.</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FlexBox sx={{ mb: 2 }}>
              <Grading sx={{ fontSize: 24 }} />
              <Typography variant="h5" fontWeight="bolder">
                Últimos Lançes
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Link variant="subtitle1" sx={{cursor:'pointer'}} onClick={() => setValue(4)}>Ver todos os lançes</Link>
              <Divider />
              <IconButton>
                <ArrowUpward />
              </IconButton>
            </FlexBox>
            <Divider />
            <Box my={1} p={1}>
              <Typography>Não existem lançes em leilões recentes.</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FlexBox sx={{ mb: 2 }}>
              <ShoppingCart sx={{ fontSize: 24 }} />
              <Typography variant="h5" fontWeight="bolder">
                Últimas Compras
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Link variant="subtitle1" sx={{cursor:'pointer'}} onClick={() => setValue(6)}>Ver todos as compras</Link>
              <Divider />
              <IconButton>
                <ArrowUpward />
              </IconButton>
            </FlexBox>
            <Divider />
            <Box my={1} p={1}>
              <Typography>Não existem compras recentes.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Resume;
