import { Box, Button, Container, Grid, Typography } from "@mui/material";

const Home = () => {
  const services = [
    " 👍 Avaliação de peças de arte e antiguidades por especialistas renomados. ",
    " 💼 Especialistas em peças de porcelana para atender suas necessidades. ",
    " 🌟 Produtos exclusivos, escolhidos a dedo para levar excelência até você. ",
    " 💰 Preços competitivos para que você possa adquirir suas preciosidades. ",
    " 🌐 Acesso online fácil e seguro para leilões e compras. ",
  ];

  const faq = [
    {
      question: "❓ Quais são as formas de pagamento aceitas?",
      answer: "Neste momento, estamos aceitando pagamentos somente via pix.",
    },
    {
      question: "❓ Como faço para participar de um leilão? ",
      answer:
        "Basta realizar o cadastro no site entrar no link da peça que desejar marcar o lançe.",
    },
    {
      question: "❓ Como faço para participar de um leilão? ",
      answer:
        "Você pode enviar a sua peça para avaliação acessando o link como vender no menu superior.",
    },
    {
      question: "❓  Quanto tempo os leilões online costumam durar?",
      answer:
        "Os leilões duram em média 3 horas (podendo ser maior ou menor a depender da demanda e do leiloeiro).",
    },
    {
      question: "❓ Como faço para entrar em contato com a Esmeralda Leilões?",
      answer:
        "Você pode entrar em contato conosco através do nosso WhatsApp ou do nosso e-mail.",
    },
  ];
  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
            minHeight: "60vh",
          }}
        >
          <Box
            sx={{
              width: 250,
              height: 250,
              borderRadius: "100%",
              bgcolor: "background.main",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              p: 2,
              mb: 4,
            }}
          >
            <img
              src={require("../../assets/logo.jpg")}
              alt="esmeralda leilões - leilão de artes e antiguidades"
              style={{
                width: "100%",
                maxWidth: 200,
                height: "100%",
                objectFit: "contain",
                alignContent: "center",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Esmeralda Leilões
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Descubra a magia das peças de arte e antiguidades que marcaram a
              história.
            </Typography>
          </Container>
        </Box>
      </Container>

      <Box
        id="sobre"
        sx={{
          bgcolor: "primary.main",
          color: "rgb(210, 172, 65)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              minHeight: "50vh",
              p: 4,
            }}
          >
            <Typography variant="h5">
              Com mais de 10 anos de experiência no mercado, nossa motivação
              sempre foi contar as histórias por trás das obras de arte e
              preciosidades encontradas em museus ao redor do mundo. Seja você
              um apaixonado pela cultura, um entusiasta das compras ou um
              curioso em busca de conhecimento, estamos aqui para te encantar!
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            minHeight: "60vh",
            // textAlign:'justify',
            my: 2,
            py: 2,
            p: 4,
          }}
        >
          <Typography variant="h3" mb={6}>
            Nossos Serviços
          </Typography>
          <Grid container spacing={4}>
            {services.map((service) => (
              <Grid item>
                <Typography variant="h4" key={service}>
                  {service}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          bgcolor: "primary.main",
          color: "rgb(210, 172, 65)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              textAlign: "justify",
              minHeight: "80vh",
              p: 4,
              my: 2,
            }}
          >
            <Typography variant="h3" mb={6}>
              Perguntas Frequentes
            </Typography>
            <Grid container spacing={4}>
              {faq.map((fq) => (
                <Grid item xs={12} key={fq.question}>
                  <Typography variant="h4">{fq.question}</Typography>
                  <Typography variant="h6" mt={1}>
                    {fq.answer}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            minHeight: "60vh",
            // textAlign:'justify',
            my: 2,
            py: 2,
            p: 4,
          }}
        >
          <Typography variant="h3" mb={6}>
            Captação de Peças
          </Typography>
          <Grid container spacing={4}>
            <Grid item>
              <Typography
                variant="h4"
                sx={{ textAlign: { xs: "center", md: "justify" } }}
                color="text.secondary"
                paragraph
                mb={4}
              >
                Estamos em captação permanente de obras de arte para leilões
                (quadros, pratarias, móveis, porcelanas, cristais, tapetes
                orientais, imagens, sacras, marfins, objetos de coleção,
                curiosidades e outros).
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: { xs: "center", md: "left" },
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  underline="hover"
                  target="_blank"
                  size="large"
                  href="https://api.whatsapp.com/send?phone=5521972525983&text=Ol%C3%A1,%20eu%20gostaria%20de%20obter%20mais%20detalhes%20sobre%20a%20capta%C3%A7%C3%A3o%20de%20pe%C3%A7as%20para%20leil%C3%B5es%20na%20Esmeralda%20Leil%C3%B5es%20"
                >
                  Envie sua peça para avaliação
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Home;
