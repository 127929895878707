import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import MyTextField from "../../../components/text/MyTextField";
import { useForm } from "react-hook-form";

const CompleteSignUp = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const inputs = [
    {
      name: "name",
      label: "Nome Completo",
      required: true,
    },
    {
      name: "cpf",
      label: "Número do CPF",
      required: true,
      disabled: true,
    },
    {
      name: "rg",
      label: "Número do RG",
      required: true,
    },
    {
      name: "birthday",
      label: "Data de Nascimento",
      required: true,
    },
    {
      name: "sex",
      label: "Sexo",
      required: true,
    },
    {
      name: "email",
      label: "Endereço de E-mail",
      required: true,
      disabled: true,
    },
    {
      name: "password",
      label: "Senha",
      required: true,
      type: "password",
    },
    {
      name: "password_confirmation",
      label: "Confirmação de Senha",
      required: true,
      type: "password",
    },
  ];

  const address = [
    {
      name: "cellNumber",
      label: "Telefone Celular",
      required: true,
    },
    {
      name: "phoneNumber",
      label: "Telefone Fixo",
    },
    {
      name: "cep",
      label: "CEP",
      required: true,
    },
    {
      name: "street",
      label: "Endereço",
      disabled: true,
      required: true,
    },
    {
      name: "number",
      label: "Número",
      required: true,
    },
    {
      name: "complement",
      label: "Complemento",
      required: true,
    },
    {
      name: "neighborhood",
      label: "Bairro",
      required: true,
      disabled: true,
    },
    {
      name: "city",
      label: "Cidade",
      required: true,
      disabled: true,
    },
    {
      name: "state",
      label: "Estado",
      required: true,
      disabled: true,
    },
  ];
  return (
    <Container maxWidth="xl">
      <Typography variant="h3" mb={1} my={3}>
        Completar Cadastro
      </Typography>

      <Divider />
      <Grid container spacing={4} py={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" mb={1}>
            SEU CADASTRO
          </Typography>
          <MyTextField inputs={inputs} register={register} errors={errors} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" mb={1}>
            SEU ENDEREÇO
          </Typography>
          <MyTextField inputs={address} register={register} errors={errors} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Box sx={{ gap: 1, my: 2, display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              required
              control={<Checkbox value="remember" color="primary" />}
              label="Concordo que LI e ACEITO os Termos de Uso e Política de Privacidade do Site."
            />
            <FormControlLabel
              control={
                <Checkbox value="remember" color="primary" defaultChecked />
              }
              label="Desejo receber ofertas e comunicados via e-mail."
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="contained"
                size='large'
                // color="inherit"
                onClick={handleSubmit(onSubmit)}
              >
                Continuar Cadastro
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompleteSignUp;
