import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import MyTextField from "../../../components/text/MyTextField";
import { FlexBox } from "../../../components/FlexBox";
import { Person } from "@mui/icons-material";

const MyAccount = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  const inputs = [
    {
      name: "cpf",
      label: "Número do CPF",
      required: true,
      disabled: true,
    },
    {
      name: "name",
      label: "Nome Completo",
      required: true,
      disabled: true,
    },
    {
      name: "email",
      label: "Endereço de E-mail",
      required: true,
      disabled: true,
    },
    {
      name: "rg",
      label: "Número do RG",
      required: true,
    },
    {
      name: "sex",
      label: "Sexo",
      required: true,
    },
    {
      name: "cellNumber",
      label: "Telefone Celular",
      required: true,
    },
    {
      name: "phoneNumber",
      label: "Telefone Fixo",
    },
    {
      name: "cep",
      label: "CEP",
      required: true,
    },
    {
      name: "street",
      label: "Endereço",
      disabled: true,
      required: true,
    },
    {
      name: "number",
      label: "Número",
      required: true,
    },
    {
      name: "complement",
      label: "Complemento",
      required: true,
    },
    {
      name: "neighborhood",
      label: "Bairro",
      required: true,
      disabled: true,
    },
    {
      name: "city",
      label: "Cidade",
      required: true,
      disabled: true,
    },
    {
      name: "state",
      label: "Estado",
      required: true,
      disabled: true,
    },
  ];
  return (
    <>
      <FlexBox sx={{ mb: 2 }}>
        <Person sx={{ fontSize: 32 }} />
        <Typography variant="h4" fontWeight='bolder'>Meus Dados</Typography>
      </FlexBox>
      <Divider />
      <Box my={1}>
        <MyTextField inputs={inputs} register={register} errors={errors} />
        <Button variant="contained" sx={{ my: 2 }}>
          Atualizar Informações
        </Button>
      </Box>
    </>
  );
};

export default MyAccount;
