import {
  ImportContacts,
  Info,
  Instagram,
  Mail,
  Map,
  Person,
  Phone,
  ShoppingCart,
  Store,
  WhatsApp,
} from "@mui/icons-material";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
let logo = require("../assets/logo.jpg");

const FlexBox = ({ children }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>{children}</Box>
);

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        minHeight: "30vh",
        py: 6,
        px: 3,
        // backgroundColor: '#00a99d',
        bgcolor: "primary.main",
        color: "#f1f1f1",
        width: "100%",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Box>
              <img src={logo} height="100" />
              <Typography variant="h5">Esmeralda Leilões</Typography>
              <Typography variant="subtitle1">
                CNPJ: 50.645.363/0001-52
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={2}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              <Typography variant="h5" mb={1}>
                Conheça o Site
              </Typography>
              <FlexBox>
                <ImportContacts />
                <Typography
                  variant="subtitle1"
                  onClick={() => navigate("/catalogos")}
                  sx={{ cursor: "pointer" }}
                >
                  Catálogos
                </Typography>
              </FlexBox>
              <FlexBox>
                <ShoppingCart />
                <Typography
                  variant="subtitle1"
                  onClick={() => navigate("/como-comprar")}
                  sx={{ cursor: "pointer" }}
                >
                  Como Comprar
                </Typography>
              </FlexBox>
              <FlexBox>
                <Store />
                <Typography
                  variant="subtitle1"
                  onClick={() => navigate("/como-vender")}
                  sx={{ cursor: "pointer" }}
                >
                  Como Vender
                </Typography>
              </FlexBox>
              <FlexBox>
                <Info />
                <Typography
                  variant="subtitle1"
                  onClick={() => navigate("/quem-somos")}
                  sx={{ cursor: "pointer" }}
                >
                  Quem Somos
                </Typography>
              </FlexBox>
              <FlexBox>
                <Info />
                <Typography
                  variant="subtitle1"
                  onClick={() => navigate("/fale-conosco")}
                  sx={{ cursor: "pointer" }}
                >
                  Fale Conosco
                </Typography>
              </FlexBox>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              <Typography variant="h5" mb={1}>
                Fale Conosco
              </Typography>
              <FlexBox>
                <Person />
                <Typography variant="subtitle1">
                  Atendimento de 2ª a 6ª-feira das 10h30 às 17h.
                </Typography>
              </FlexBox>
              <FlexBox>
                <Phone />
                <Typography
                  variant="subtitle1"
                  sx={{ cursor: "pointer" }}
                  onClick={() => window.open("tel://+5521972525983", "_self")}
                >
                  (21) 97252-5983
                </Typography>
              </FlexBox>
              <FlexBox>
                <Mail />
                <Typography
                  variant="subtitle1"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("mailto://esmeralda.leiloes@gmail.com", "_self")
                  }
                >
                  esmeralda.leiloes@gmail.com
                </Typography>
              </FlexBox>
              <FlexBox>
                <Map />
                <Typography
                  variant="subtitle1"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://maps.app.goo.gl/3PdmLCFy3AWWUpdKA",
                      "_blank"
                    )
                  }
                >
                  R. Calmom Cabral, 42 - Irajá, Rio de Janeiro, RJ
                </Typography>
              </FlexBox>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box>
              <Typography variant="h5" mb={1}>
                Redes Sociais
              </Typography>
              <FlexBox>
                <WhatsApp
                  sx={{ fontSize: 32, cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=5521972525983&text=Ol%C3%A1,%20eu%20gostaria%20de%20obter%20maiores%20informa%C3%A7%C3%B5es%20sobre%20a%20venda%20de%20pe%C3%A7as%20em%20leil%C3%A3o%20online.",
                      "_blank"
                    )
                  }
                />
                <Instagram
                  sx={{ fontSize: 32, cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://instagram.com/esmeralda.leiloes",
                      "_blank"
                    )
                  }
                />
              </FlexBox>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
