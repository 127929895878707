import { Box, Container, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import MyTextField from "../../components/text/MyTextField";
import { useForm } from "react-hook-form";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../assets/configs/firebase.config";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import LoadButton from "../../components/LoadButton";
import { FlexBox } from "../../components/FlexBox";

const Login = () => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  const inputs = [
    {
      label: "Endereço de E-mail",
      name: "email",
      required: true,
      type: "email",
      grid: 12,
    },
    {
      name: "password",
      label: "Senha",
      required: true,
      type: "password",
      grid: 12,
    },
  ];

  const onSubmit = (data) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then(() => navigate("/minha-conta"))
      .catch((e) => {
        let message;

        if (e.message == "Firebase: Error (auth/user-not-found).")
          message = "Ops... E-mail não encontrado na base de dados.";
        if (e.message == "Firebase: Error (auth/wrong-password).")
          message = "Ops... E-mail ou senha incorretos.";
        else message = "Ocorreu um erro desconhecido.";

        console.log(e.message);
        setErrorMessage(message);
      });
  };
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          my: 2,
          display: "flex",
          flexDirection: "column",
          p: 4,
        }}
        component={Paper}
      >
        <Typography component="h1" variant="h4">
          Entrar
        </Typography>
        <Typography variant="subtitle1" mt={1}>
          Para entrar, por favor, insira as informações abaixo:
        </Typography>
        {errorMessage && (
          <Typography color="error" mt={1}>
            {errorMessage}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <MyTextField
            inputs={inputs}
            register={register}
            errors={errors}
            loading={loading}
          />

          <LoadButton
            props={{
              loading: loading,
              fullWidth: true,
              sx: { my: 2 },
              size:'large',
              variant: "contained",
              type: "submit",
            }}
          >
            entrar na conta
          </LoadButton>
          <FlexBox sx={{justifyContent:'space-between'}}>
            <Link href="#" variant="subtitle1">
              Esqueceu a Senha?
            </Link>
            <Link
              onClick={() => navigate("/contas/cadastro")}
              variant="subtitle1"
              sx={{ cursor: "pointer" }}
            >
              {"Ainda não tem conta? Cadastre-se"}
            </Link>
          </FlexBox>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
