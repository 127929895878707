import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { FlexBox } from "../../../components/FlexBox";
import MyTextField from "../../../components/text/MyTextField";
import { useForm } from "react-hook-form";
import LoadButton from "../../../components/LoadButton";

const HowToSell = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  const _string = `
  Se desejar vender seus bens (Quadros, Móveis, Pratarias, Porcelanas, Cristais, Vasos, Objetos de Marfim, Tapetes, Esculturas, Peças de Mármore, Jóias, Antiguidades etc), teremos máximo prazer em lhe oferecer uma equipe de especialistas que lhe darão total atenção.

  Para obter avaliação de seu bem, basta executar os passos a seguir:
  
  1 - Selecionar as fotos do item que deseja avaliar (máximo de 10 fotos por envio através do formulário ao lado - nos formatos .JPG ou .PNG).
  
  2 - Fazer uma breve descrição do item e observações sobre seu estado (se possui algum defeito, vício ou avaria).
  
  3 - Enviar Avaliação.
  
  O serviço de avaliação é totalmente gratuito e efetivado com base nas últimas vendas realizadas no mercado e no expertise dos responsáveis pela Galeria e/ou Casa de Leilão.
  
  Após concordância quanto à avaliação, será formalizado um pequeno contrato autorizando a venda do bem em leilão.
  
  O transporte do bem, do local de origem até o endereço da galeria e/ou Casa de Leilão, deverá ser acordado entre o proprietário da peça e a galeria que realizará a venda em leilão.
  
  Ao término do leilão, o vendedor receberá a relação dos objetos vendidos e respectivo pagamento, abatido a comissão acordada em contrato, no prazo máximo de até 15 (quinze) dias úteis após finalização do leilão.
  
  Caso não ocorra a venda, o proprietário terá opção de retirar o bem ou disponibilizá-lo para nova divulgação e venda em leilão.`;

  return (
    <Container maxWidth="xl" sx={{mb:4}}>
      <Typography variant="h3" my={3}>
        COMO VENDER
      </Typography>
      <Divider></Divider>
      <Grid container my={1}>
        <Grid item xs={12} md={6} sx={{ alignItems: "center" }}>
          <Box
            sx={{
              width: "auto !important",
              maxWidth: {xs: 555, md: 'auto'},
              height: 260,
              justifyContent: "flex-end",
              alignSelf: "flex-end",
              mt: 1,
            }}
          >
            <iframe
              width="100%"
              height="260"
              src="https://www.youtube.com/embed/vgcUw8VRsoM"
              title="Sistema de leilão eletrônico"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
          {_string
            .split("\n")
            .filter((item) => item !== "")
            .map((item) => {
              const theObj = { __html: item };
              return (
                <Typography mt={2} textAlign="justify">
                  <div dangerouslySetInnerHTML={theObj} />
                </Typography>
              );
            })}
        </Grid>

        <Grid item xs={12} md={6} pl={2}>
          <Typography variant='h5' p={1}>Faça sua Avaliação:</Typography>
          <FlexBox sx={{ px:1, alignItems:'flex-start' }}>
            <Typography variant='h6'>1. </Typography>
            <Container>
              <Typography mb={1} variant='h6'>
                Escolha as imagens das peças a serem avaliadas:
              </Typography>
              {/* <Button>Upload</Button> */}
              <input type='file'/>
            </Container>
          </FlexBox>
          <FlexBox sx={{ pt: 4, px:1, alignItems:'flex-start' }}>
            <Typography variant='h6'>2. </Typography>
            <Container>
              <Typography mb={1} variant='h6'>
                Faça as suas observações ou digite uma breve descrição sobre as
                peças:
              </Typography>
              <MyTextField
                inputs={[
                  {
                    label: false,
                    name: "description",
                    size: 12,
                    multiline: true,
                  },
                ]}
                register={register}
                errors={errors}
              />
            </Container>
          </FlexBox>
          <FlexBox sx={{ pt: 4, px:1, alignItems:'flex-start' }}>
            <Typography variant='h6'>3.</Typography>
            <Container>
              <Typography variant='h6'>
                Envie a sua avaliação! Em breve entraremos em contato com você.
              </Typography>
              <LoadButton variant="contained" fullWidth sx={{mt:2}} props={{loading:false, disabled:true}}>
                Enviar para Avaliação
              </LoadButton>
            </Container>
          </FlexBox>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HowToSell;
