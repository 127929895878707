import React from "react";
import { LoadingButton } from "@mui/lab";

const LoadButton = ({ props, children }) => {
  return (
    <LoadingButton
      loading={true}
      {...props}
      variant="contained"
      fullWidth
      sx={{ my: 2 }}
    >
      {children}
    </LoadingButton>
  );
};

export default LoadButton;
