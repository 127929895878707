import React, { useEffect, useState } from "react";
import { TextField, Box, Grid, Typography } from "@mui/material";
import cep from "cep-promise";

import {
  cepMask,
  cnpjMask,
  cpfMask,
  phoneMask,
  rgMask,
} from "../../assets/masks/masks";
const MyTextField = ({ inputs, register, errors, loading }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    let _values = { ...values };
    if (values.cnpj?.length >= 3) {
      _values.cnpj = cnpjMask(values.cnpj);
    }
    if (values.cpf?.length >= 3) {
      _values.cpf = cpfMask(values.cpf);
    }
    if (values.rg?.length >= 3) {
      _values.rg = rgMask(values.rg);
    }
    if (values.cellNumber?.length >= 3) {
      _values.cellNumber = phoneMask(values.cellNumber);
    }
    if (values.phoneNumber?.length >= 3) {
      _values.phoneNumber = phoneMask(values.phoneNumber);
    }
    setValues(_values);
  }, [
    values.cpf,
    values.rg,
    values.cellNumber,
    values.phoneNumber,
    values.cnpj,
  ]);

  useEffect(() => {
    if (values.cep?.length >= 5 && !values.street) {
      try {
        cep(values.cep).then((address) => {
          setValues({
            ...values,
            ...address,
            cep: cepMask(address.cep),
            number: "",
            complement: "",
            reference: "",
          });
        }).catch((e) => console.log(e));
      } catch (e) {
        alert(e);
      }
    }
  }, [values.cep]);

  return (
    <Box component="form">
      <Grid container>
        {inputs.map((input) => (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {input.label && (
                <Typography sx={{ flex: 1 }}>
                  {input.label}
                  {input.required && "*"}:
                </Typography>
              )}
              <TextField
                sx={{ flex: 2 }}
                margin="dense"
                fullWidth
                id={input.name}
                // autoComplete="email"
                autoFocus
                type={input.type}
                multiline={input.multiline}
                rows={10}
                size="small"
                // label={input.label}
                disabled={loading}
                required={input.required}
                error={errors[input.name]}
                helperText={errors[input.name] && input.helperText}
                {...register(input.name, { ...input })}
                value={values[input.name]}
                onChange={(e) =>
                  setValues({ ...values, [input.name]: e.target.value })
                }
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyTextField;
