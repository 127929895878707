import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { eLeiloesTheme } from "./assets/theme/theme";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={eLeiloesTheme}>
      <RouterProvider router={routes} />
    </ThemeProvider>
  </React.StrictMode>
);
