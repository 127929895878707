import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import MyTextField from "../../../components/text/MyTextField";
import { useForm } from "react-hook-form";
import LoadButton from "../../../components/LoadButton";
import { WhatsApp } from "@mui/icons-material";
import { FlexBox } from "../../../components/FlexBox";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [captcha, setCaptcha] = useState(false);

  function onClick() {
    if (captcha) alert("captcha resolvido");
    else alert("captcha pendente");
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const inputs = [
    {
      name: "name",
      label: "Nome Completo",
      required: true,
    },

    {
      name: "email",
      label: "Endereço de E-mail",
      required: true,
      disabled: true,
    },
    {
      name: "subject",
      label: "Assunto",
      options: [
        { label: "Financeiro" },
        { label: "Bloqueio" },
        { label: "Reclamações/Elogios" },
        { label: "Dúvidas" },
      ],
      required: true,
    },
    {
      name: "comments",
      label: "Comentários",
      required: true,
      multiline: true,
    },
  ];

  return (
    <Container maxWidth="xl" pb={2}>
      <Typography variant="h3" my={3}>
        FALE CONOSCO
      </Typography>
      <Divider />
      <Typography mt={2}>
        Por telefone ou e-mail, se você tiver alguma pergunta, comentário ou
        sugestão, sinta-se à vontade para nos contactar.
      </Typography>

      <Grid container spacing={4} mb={4}>
        <Grid item xs={12} md={6} mt={2}>
          <Typography variant="h6">Contato por E-mail</Typography>
          <MyTextField inputs={inputs} register={register} errors={errors} />
          <Box
            sx={{
              width: "100%",
              py: 1,
              justifyContent: "center",
              display: "flex",
            }}
          >
            {/* <ReCAPTCHA
              sitekey='6Lc_cwgnAAAAAOeRpTs4yStdyb9aRdL8urTwiMCm'
              onChange={setCaptcha}
            /> */}
          </Box>
          <LoadButton props={{ loading: false, disabled: !captcha }}>
            Enviar
          </LoadButton>
        </Grid>
        <Grid item xs={12} md={6} my={2}>
          <Typography variant="h6">Contato por Telefone</Typography>
          <Typography mt={2}>
            Atendimento de 2ª a 6ª-feira das 10h30 às 17h.
          </Typography>
          <FlexBox sx={{ my: 1, cursor: "pointer" }}>
            <WhatsApp
              sx={{ fontSize: 32 }}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=5521964526521&text=Ol%C3%A1,%20eu%20gostaria%20de%20obter%20maiores%20informa%C3%A7%C3%B5es%20sobre%20a%20venda%20de%20pe%C3%A7as%20em%20leil%C3%A3o%20online.",
                  "_blank"
                )
              }
            />
            <Typography  onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=5521964526521&text=Ol%C3%A1,%20eu%20gostaria%20de%20obter%20maiores%20informa%C3%A7%C3%B5es%20sobre%20a%20venda%20de%20pe%C3%A7as%20em%20leil%C3%A3o%20online.",
                  "_blank"
                )
              }>(21) 9 7252-5983</Typography>
          </FlexBox>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;
