import { Box, Divider, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FlexBox } from "../../../components/FlexBox";
import { ShoppingCart } from "@mui/icons-material";

const MyBoughts = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  const inputs = [
    {
      name: "email",
      label: "Seu Endereço de E-mail",
      required: true,
      disabled: true,
    },
    {
      name: "email",
      label: "Novo E-mail",
      required: true,
    },
    {
      name: "email",
      label: "Confirme o Novo E-mail",
      required: true,
    },
  ];
  return (
    <>
      <FlexBox sx={{ mb: 2 }}>
        <ShoppingCart sx={{ fontSize: 32 }} />
        <Typography variant="h4" fontWeight='bolder'>Minhas Compras</Typography>
      </FlexBox>
      <Divider />
      <Box my={1} p={1}>
        <Typography>Não existem lances efetuados.</Typography>
      </Box>
    </>
  );
};

export default MyBoughts;
