import {
  Outlet,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import App from "./App";
import Login from "./pages/auth";
import SignUp from "./pages/auth/pages/PreCadastro";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./assets/configs/firebase.config";
import { CircularProgress, Box } from "@mui/material";
import CompleteSignUp from "./pages/auth/pages/CompletarCadastro";
import Loged from "./pages/app/App";
import HowToBuy from "./pages/home/sections/ComoComprar";
import HowToSell from "./pages/home/sections/ComoVender";
import Contact from "./pages/home/sections/Contato";
import EmBreve from "./pages/home/sections/EmBreve";
import Home from "./pages/home/Home";
import Landing from "./pages/landing/Landing";

const ProtectedRoute = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  setTimeout(() => {
    if (!loading) {
      if (!user) return navigate("/contas/login");

      return <Outlet />;
    }
  }, 500);

  return (
    <>
      {user ? (
        <Outlet />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "20vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export const routes = createBrowserRouter([
  {
    index: true,
    element: <Landing />,
  },
  {
    path: "*",
    element: <App />,
    children: [
      { path: "home", element: <Home /> },
      { path: "catalogos", element: <EmBreve /> },
      { path: "comprar", element: <HowToBuy /> },
      { path: "vender", element: <HowToSell /> },
      { path: "contato", element: <Contact /> },
      {
        path: "contas",
        children: [
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "cadastro",
            element: <SignUp />,
          },
          {
            path: "completar-cadastro",
            element: <CompleteSignUp />,
          },
        ],
      },
      {
        path: "minha-conta",
        element: <ProtectedRoute />,
        children: [
          {
            index: true,
            path: "",
            element: <Loged />,
          },
        ],
      },
    ],
  },
]);
