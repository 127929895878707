import {
  AccountBox,
  Dashboard,
  Grading,
  Lock,
  Mail,
  Password,
  Person,
  PointOfSale,
  RemoveRedEye,
  Sell,
  ShoppingBag,
  ShoppingCart,
  Star,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { FlexBox } from "../../components/FlexBox";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../assets/configs/firebase.config";
import MyAccount from "./pages/MeusDados";
import ChangeEmail from "./pages/AlterarEmail";
import ChangePassword from "./pages/AlterarSenha";
import MyBids from "./pages/MeusLances";
import MyPieces from "./pages/MinhasPecas";
import MyMessages from "./pages/MinhasMensagens";
import MyWatches from "./pages/MeusVigias";
import MyFavorites from "./pages/MeusFavoritos";
import MyBoughts from "./pages/MinhasCompras";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import Resume from "./pages/Resumo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2, pl: { xs: 0, md: 4 } }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Loged = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogout = () => {
    if (window.confirm("Tem certeza de que deseja sair do sistema?")) {
      signOut(auth);
      alert("Ate breve!");
      navigate("/");
    }
  };

  const menuTabs = [
    { label: "Resumo", icon: <Dashboard />, element: <Resume setValue={setValue}/> },
    { label: "Meus Dados", icon: <Person />, element: <MyAccount /> },
    { label: "Alterar E-mail", icon: <Mail />, element: <ChangeEmail /> },
    { label: "Alterar Senha", icon: <Lock />, element: <ChangePassword /> },
    { label: "Meus Lances", icon: <Grading />, element: <MyBids /> },
    { label: "Minhas Peças", icon: <Sell />, element: <MyPieces /> },
    { label: "Minhas Compras", icon: <ShoppingCart />, element: <MyBoughts /> },
    { label: "Meus Favoritos", icon: <Star />, element: <MyFavorites /> },
    { label: "Minhas Vigias", icon: <RemoveRedEye />, element: <MyWatches /> },
    { label: "Mensagens", icon: <Mail />, element: <MyMessages /> },
  ];
  return (
    <Container maxWidth="xxl" spacing={1}>
      <Tabs
        variant={window.innerWidth <= 600 ? "scrollable" : "fullWidth"}
        centered={window.innerWidth <= 600 ? false : true}
        allowScrollButtonsMobile
        scrollButtons={window.innerWidth <= 600 ? false : true}
        aria-label="scrollable auto tabs example"
        sx={{ my: 2 }}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
      >
        {menuTabs.map((tab, index) => (
          <Tab label={tab.label} icon={tab.icon} {...a11yProps(index)} />
        ))}
      </Tabs>
      <Grid container sx={{ minHeight: "40vh" }}>
        <Grid item xs={0} md={3} sx={{ display: { xs: "none", md: "block" } }}>
          <Divider />
          <FlexBox sx={{ gap: 2, my: 2 }}>
            <Person sx={{ fontSize: 32 }} />
            <Box>
              <Typography variant="h5">Minha Conta</Typography>
              <Typography>Cartela: 123456</Typography>
              <Link
                sx={{ fontSize: 12, cursor: "pointer" }}
                onClick={handleLogout}
              >
                Sair
              </Link>
            </Box>
          </FlexBox>
          <Divider />
          <Box pt={2} px={2}>
            <Typography variant="subtitle2">Nome:</Typography>
            <Typography variant="body1">{user.displayName}</Typography>
          </Box>
          <Box px={2} py={1}>
            <Typography variant="subtitle2">Email:</Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>
          <Divider />
          <FlexBox sx={{ my: 1 }}>
            <Button fullWidth variant="outlined" onClick={() => setValue(1)}>
              Alterar Dados
            </Button>
          </FlexBox>
        </Grid>
        <Grid item xs={12} md={9}>
          <Divider />

          {menuTabs.map((tab, index) => (
            <TabPanel value={value} index={index}>
              {tab.element}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Loged;
