import { Box, Container } from "@mui/material";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        height:'100%',
        backgroundColor: "#F2F2F2",
        color: "#333333",
        display: "flex",
        flexDirection: "column",
      }}
      fullWidth
    >
      <Navbar />
      <Box  sx={{ my:0, flex:1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
