import { createTheme, responsiveFontSizes } from "@mui/material";

export const eLeiloesTheme = responsiveFontSizes(
  createTheme({
    typography: {
      fontSize: 11,
      fontColor: '#D2AC41',
    },
    palette: {
      mode: 'light',
      primary: {
        main: "#044b23",
      },
      background:{
        main: '#044b23'
      }
      // secondary: {
      //   main: '#e65100',
      // },
    },
  })
);
